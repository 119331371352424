import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';

export default function DrawControl({ parkings, ...props }) {
    useControl(
        () => new MapboxDraw(props),
        ({ map }) => {
            map.on('draw.create', (e) => props.onCreate(e, parkings));
            map.on('draw.update', (e) => props.onUpdate(e, parkings));
            map.on('draw.delete', (e) => props.onDelete(e, parkings));
        },
        ({ map }) => {
            map.off('draw.create', props.onCreate);
            map.off('draw.update', props.onUpdate);
            map.off('draw.delete', props.onDelete);
        },
        {
            position: props.position,
        }
    );

    return null;
}

DrawControl.defaultProps = {
    onCreate: () => {},
    onUpdate: () => {},
    onDelete: () => {},
};
