import {
    Avatar,
    Box,
    Container,
    Dialog,
    IconButton,
    Rating,
    Slide,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    Divider,
    Button,
  } from '@mui/material';
  import { forwardRef, useEffect, useState } from 'react';
  import { useValue } from '../../context/ContextProvider';
  import { Close, StarBorder } from '@mui/icons-material';
  import { useParams } from 'react-router-dom';
  import { getRoomById } from '../../actions/room';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/effect-coverflow';
  import 'swiper/css/zoom';
  import './locationSwiper.css';
  import { useTheme } from '@mui/material/styles';
  import ReactMapGL, { Marker } from 'react-map-gl';
  import {useNavigate } from 'react-router-dom';
  import HomeIcon from '@mui/icons-material/Home';
  import LocationDetail from './LocationDetail';

  const LocationMap = () => {
    const navigate = useNavigate();
    const {
      state: {
        room: {lat: locLat, lng: locLng, title, openHours,images, price, uName, uid, uPhoto, description, createdAt, _id},
        buddyDetails: { firstName, lastName, email, phone, dates, linkedIn, lat, lng, address, addressName, zipCode, hostName},
     },
      dispatch,
    } = useValue();

    const theme = useTheme();

    const { roomId } = useParams(); 

    // const [curZoom, setCurZoom] = useState(14);
  
    // Calculate the size of the circle based on the zoom level
    // const circleSize = Math.max(0, 200 - (14 - curZoom) * 100);

    // console.log("---circleSize is: ", circleSize);

    useEffect(() => {
      if (!locLat && !locLng && roomId) {
        getRoomById(dispatch, roomId)
      }
    }, [locLat, locLng, roomId]);

    const Transition = forwardRef((props, ref) => {
        return <Slide direction="up" {...props} ref={ref} />;
    });

    const handleClose = () => {
        navigate(`/locations/${roomId}`);
      };


    if (!locLat && !locLng && roomId){
        return <LocationDetail openMap={true}/>
    }
  
    return (
        <Dialog
        fullScreen
        open={Boolean(locLat)}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ padding: '0 0 0 0'}}
      >
            <Container sx={{ padding: '0 0 0 0', position: 'relative' }}>
            <IconButton 
                    color="inherit" 
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 16, // Adjust as needed
                        left: 16, // Adjust as needed
                        zIndex: 5, // Ensure it's above other elements
                        width: 48, // Square size
                        height: 48, // Square size
                        backgroundColor: 'white', // Background color
                        borderRadius: 0, // Make it square
                        borderRadius: '8px', // Rounded corners
                        '&:hover': {
                            backgroundColor: '#e0e0e0', // Background color on hover
                        }
                    }}
                >
                    <Close />
                </IconButton>
            {locLat && locLng && 
                <Box sx={{ width: '100vw', height: '100vh', margin: 0, padding: 0}}>
                <ReactMapGL 
                    initialViewState={{
                        latitude: locLat,   
                        longitude: locLng, 
                        zoom: 14,
                    }}
                    width="100%"
                    height="100%"
                    mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    // onZoomEnd={e => setCurZoom(e.viewState.zoom)}
                    scrollZoom={true}
                >
                    <Marker 
                        latitude={locLat} 
                        longitude={locLng}
                        offsetLeft={-20} 
                        offsetTop={-10}
                    >
                        {/* Semi-transparent circle */}
                        <Box sx={{
                            position: 'absolute',
                            top: '50%', // Center vertically in relation to the marker
                            left: '50%', // Center horizontally in relation to the marker
                            width: '200px',
                            height: '200px',
                            // width: `${circleSize}px`,
                            // height: `${circleSize}px`,
                            bgcolor: 'rgba(255, 84, 101, 0.22)', // Semi-transparent #ff5465
                            borderRadius: '50%',
                            transform: 'translate(-50%, -50%)', // Adjust for centering
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1, // Ensure it's under the house icon
                        }}>
                        </Box>

                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '55px',
                            height: '55px',
                            bgcolor: '#ff5465',
                            borderRadius: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                        }} />

                        <Box sx={{ position: 'relative', zIndex: 3, color: 'white'}}>
                            <HomeIcon fontSize='large'/>
                        </Box>
                    </Marker>
                </ReactMapGL>
                </Box>
            }            
            </Container>
       </Dialog>
        
    );
  };
  
  export default LocationMap;
  