import {
    Avatar,
    Box,
    Container,
    Dialog,
    IconButton,
    Rating,
    Slide,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    Divider,
    Button,
  } from '@mui/material';
  import { forwardRef, useEffect, useState } from 'react';
  import { useValue } from '../../context/ContextProvider';
  import { Close, StarBorder } from '@mui/icons-material';
  import StarIcon from '@mui/icons-material/Star';
  import { useParams } from 'react-router-dom';
  import { getRoomById } from '../../actions/room';
  import Header from '../buddySignUp/Header';
  import CalendarDatePicker from '../buddySignUp/MultiDatePicker';
  import { Calendar, DateObject } from "react-multi-date-picker"
  
  import { Swiper, SwiperSlide } from 'swiper/react';
  import { Navigation, Autoplay, EffectCoverflow, Lazy, Zoom, Pagination, Keyboard } from 'swiper';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/effect-coverflow';
  import 'swiper/css/zoom';
  import './locationSwiper.css';
  import { styled } from '@mui/material/styles';
  import FavoriteIcon from '@mui/icons-material/Favorite';
  import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
  import WifiIcon from '@mui/icons-material/Wifi';
  import LocalParkingIcon from '@mui/icons-material/LocalParking';
  import {isWeekend, getNextBusinessDay} from '../helper';
  import { useTheme } from '@mui/material/styles';
  import ReactMapGL, { Marker } from 'react-map-gl';
  import { Link, useNavigate } from 'react-router-dom';
  import HomeIcon from '@mui/icons-material/Home';
  import ImageGalleryModal from './ImageGalleryModal';

  const LocationDetail = () => {
    const navigate = useNavigate();
    const {
      state: {
        room: {lat: locLat, lng: locLng, title, openHours,images, price, uName, uid, uPhoto, description, createdAt, _id},
        buddyDetails: { firstName, lastName, email, phone, dates, linkedIn, lat, lng, address, addressName, zipCode, hostName},
     },
      dispatch,
    } = useValue();

    const theme = useTheme();
    const weekend = isWeekend();

    const isToday = (dateObject) => {
        const today = new DateObject();
        return dateObject.day === today.day &&
               dateObject.month.number === today.month.number &&
               dateObject.year === today.year;
      }


//   TODO: add an indication if request from flyer QR code 

// TODO: once someone make a booking, send email to you and user

// if book at today, $40 

// if book 24 hours ahead, $20 

//make block calendar for Aproova 

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSlideClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseImageModal = () => {
        setIsModalOpen(false);
    };


    const { roomId } = useParams(); 
  
    useEffect(() => {
      if (roomId) {
        getRoomById(dispatch, roomId)
      }
    }, [roomId]);

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
          color: '#ff6d75',
        },
        '& .MuiRating-iconHover': {
          color: '#ff3d47',
        },
      });

    const handleMapClick = () => {
        navigate('map');
    };

    if(isModalOpen){
        return (
            <ImageGalleryModal 
                images={images} 
                isOpen={isModalOpen} 
                handleClose={handleCloseImageModal} 
            />
        )
    }
  
    return (
        <>
        <Container sx={{ padding: '0 0 76px 0',}}>
          <Swiper
            allowTouchMove={true}
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
                type: 'fraction',
            }}
            modules={[Keyboard, Pagination, Navigation]}
            keyboard={{
              enabled: true,
            }}
            navigation={true}
          >
            {images?.map((url) => (
              <SwiperSlide key={url} onClick={handleSlideClick}>
                <div>
                  <img 
                  src={url} 
                  alt="room" 
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Stack sx={{ p: 2 }} spacing={1.5}>
            <Box>
                <Typography component="span" sx={{fontSize: "22px", fontWeight: 450}}>
                    {title}, California
                </Typography>
            </Box>
            <Box>
                <Typography>
                    Open {openHours}
                </Typography>
            </Box>
            <StyledRating
                name="customized-color"
                defaultValue={4.8}
                getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                precision={0.5}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
            />
           
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',  // Make sure items are in a row
                alignItems: 'center',  // Vertically align items in the center
                gap: 1                 // Add gap between Avatar and Typography
                }}
            >
                <Avatar src={uPhoto} />
                <Typography>
                    Hosted by {uName}
                </Typography>
            </Box>

            <Divider light />

            <Typography sx={{fontSize: "20px", fontWeight: 400}}>
                What this place offers:
            </Typography>
            
            <Box>
                <Typography>
                - strong Wifi connection
                </Typography>

                <Typography>
                - {description}
                </Typography>

                <Typography>
                - coffee, water, snacks
                </Typography>

                <Typography>
                - free parking on premise
                </Typography>

                {uName === 'Lijun' ? ( <Typography>
                - private park to stroll around 🌸
                </Typography>) : ''}
            </Box>

            <Divider light />

            <Box>
                <Typography sx={{fontSize: "20px", fontWeight: 400}}>
                Pick a few dates
                </Typography>
            </Box>

            <Box>
                <Typography>
                    {
                        dates && dates.length 
                            ? (
                                dates.length === 1 
                                    ? (isToday(dates[0]) ? 'Today' : '1 day') 
                                    : `${dates.length} days`
                            )
                            : 'pick a date later'
                    }
                </Typography>
            </Box>

            <Box sx={{ 
                ...theme.typography.body2,  // Apply the same font styling as Typography component
                // fontSize: '1.25em',  // Adjust for bigger font size
                display: 'flex',      // Use flexbox
                justifyContent: 'center',  // Center children horizontally
                width: '100%',       // Take up full width of parent
                color: 'text.secondary',
                '& .rmdp-wrapper': {
                    border: 'none',
                }
                }}>
                <div className="rmdp-wrapper" style={{ textAlign: 'center' }}>
                    <CalendarDatePicker 
                        initialDates={dates}
                    />
                </div>
            </Box>

            <Divider light />
            <Box>
                <Typography sx={{fontSize: "20px", fontWeight: 400}}>
                You will be here
                </Typography>
            </Box>


            {locLat && locLng && 
                <Box sx={{ width: '100%', height: '200px', borderRadius: '9px', overflow: 'hidden'}}
                onClick={handleMapClick}>
                <ReactMapGL 
                    // 37.373160, -122.061988 Apoorva's place
                    initialViewState={{
                        latitude: locLat,   
                        longitude: locLng, 
                        zoom: 14,
                      }}
                    mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    // onViewportChange={viewport => setViewport(viewport)}
                    scrollZoom={false}
                >
                    <Marker 
                        latitude={locLat} 
                        longitude={locLng}
                        offsetLeft={-20} 
                        offsetTop={-10}
                    >
                        {/* Conversation block */}
                        <Box sx={{
                            fontSize: "0.8rem", 
                            fontWeight: 400,
                            position: 'absolute',
                            bottom: '100%', // Positioning below the top edge of the house icon
                            left: '50%',
                            transform: 'translateX(-50%)',
                            mb: '25px', // Margin bottom to create space between the icon and the block
                            bgcolor: 'white',
                            p: '14px', // Padding inside the block
                            borderRadius: '10px', // Rounded corners
                            // border: '1px solid #ccc', // Border for the block
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)', // Shadow to create depth
                            zIndex: 4, // Ensure it's above other elements
                            whiteSpace: 'nowrap', // Keeps text in one line
                            maxWidth: '400px', // Adjust as needed
                            textAlign: 'center', // Center the text
                            '&:after': { // Creating the arrow using pseudo-elements
                                content: '""',
                                position: 'absolute',
                                top: '100%',
                                left: '50%',
                                borderWidth: '10px',
                                borderStyle: 'solid',
                                borderColor: 'white transparent transparent transparent',
                                transform: 'translateX(-50%)',
                            }
                        }}>
                            Exact location provided after booking.
                        </Box>

                        {/* Semi-transparent circle */}
                        <Box sx={{
                            position: 'absolute',
                            top: '50%', // Center vertically in relation to the marker
                            left: '50%', // Center horizontally in relation to the marker
                            width: '150px', // Circle size
                            height: '150px', // Circle size
                            bgcolor: 'rgba(255, 84, 101, 0.22)', // Semi-transparent #ff5465
                            borderRadius: '50%',
                            transform: 'translate(-50%, -50%)', // Adjust for centering
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1, // Ensure it's under the house icon
                        }}>
                        </Box>

                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '55px',
                            height: '55px',
                            bgcolor: '#ff5465',
                            borderRadius: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                        }} />

                        <Box sx={{ position: 'relative', zIndex: 3, color: 'white'}}>
                            <HomeIcon fontSize='large'/>
                        </Box>
                    </Marker>
                </ReactMapGL>
            </Box>

            }

            <Divider light />
            <Box>
                <Typography sx={{fontSize: "20px", fontWeight: 400}}>
                About this place
                </Typography>
            </Box>

            <Box>
                <Typography>
                    Whether you're remote worker or traveler in town for business or leisure, find your focus and creativity here.
                </Typography>
            </Box>

            <Divider light />

            <Box>
                <Typography sx={{fontSize: "20px", fontWeight: 400}}>
                Have a question?
                </Typography>
            </Box>

            <Box>
                <Typography>
                Contact host at: 
                {uName === 'Lijun' ? ' 6267419858': ' 6502832155'}
                </Typography>
            </Box>

            

          </Stack>

        </Container>
        <Box 
            sx={{ 
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'fixed', 
                bottom: 0, 
                left: 0, 
                right: 0,  // This ensures the footer spans the entire width
                backgroundColor: 'background.default', // Or any color you prefer
                padding: 2, // Optional padding for aesthetics
                display: 'flex',
                justifyContent: 'space-between', // Center content horizontally
                borderTop: '1px solid #ddd',
            }}
            >
            {/* sx={{ display: 'flex', alignItems: 'center' }} */}
            <Box>  
                <Typography variant="body1" >
                    ${price}/day
                </Typography>
                <Typography variant="body2">
                    Book 1 day ahead
                </Typography>
            </Box>
            
            <Button 
                variant="contained" 
                size="small"  
                sx={{ 
                    minWidth: '110px', 
                    borderRadius: '8px', 
                    backgroundColor: '#ff5465',
                    textTransform: 'none',
                    color: '#fff',             
                    fontSize: '0.98rem',
                    '&:hover': {
                        backgroundColor: '#ff5a60',  // Slightly darken the color on hover for a subtle effect
                    }
                }}
                onClick={() => navigate(`/booking/${roomId}`)}
                >
                Reserve
            </Button>
        </Box>
        </>
        
    );
  };
  
  export default LocationDetail;
  