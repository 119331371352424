import React, { useEffect, useState, useRef } from 'react';
import { useValue } from '../../context/ContextProvider';
import { getRooms } from '../../actions/room';
import ReactMapGL, { Marker, Popup, Source, Layer, GeolocateControl, NavigationControl} from 'react-map-gl';
import {WebMercatorViewport} from '@math.gl/web-mercator';
import Supercluster from 'supercluster';
import './cluster.css';
import { Avatar, Paper, Tooltip } from '@mui/material';
import GeocoderInput from '../sidebar/GeocoderInput';
import PopupRoom from './PopupRoom';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import MapButton from './MapButton';

const ClientMap = () => {
  const {
    state: { filteredRooms },
    dispatch,
    mapRef,
  } = useValue();

  // console.log("------filteredRooms is: ", filteredRooms)

  //test client lng, lat is:  -121.98847642041932 37.39042299272549

  

  let obj = {
    //entrance marker 
    lat: 37.39355419460149,
    lng: -122.01177338157753,

    //camera marker
    clat: 37.393860987891415,
    clng: -122.01291345873462
  }

  const [zoom, setZoom] = useState(0);

  const [lijunAddress, setLijunAddress] = useState(null);

  const [popupInfo, setPopupInfo] = useState(null);

  const [showDistanceLine, setShowDistanceLine] = useState(true);

  // const [goPointLat, setGoPointLat] = useState(lijunAddress?.lat);
  // const [goPointLng, setGoPointLng] = useState(lijunAddress?.lng);

  const geolocateControlRef = useRef();

  // console.log("-----zoom is: ", zoom); //1-22, 22 is max zoom

  const [clientLat, setClientLat] = useState(0);
  const [clientLng, setClientLng] = useState(0);

  //it should be getting only one room by room id 
  useEffect(() => {
    getRooms(dispatch);
  }, []);


  
  useEffect(() => {
    //setLijunAddress({...filteredRooms[0], lat: 37.39042299272549, lng: -121.98847642041932})
    setLijunAddress({...filteredRooms[0]})
  }, [filteredRooms]);

  useEffect(() => {
   
    if (lijunAddress?.lng && lijunAddress?.lat && mapRef?.current){
      mapRef.current.jumpTo({
        center: [lijunAddress.lng, lijunAddress.lat],
        zoom: 18,
      });

    }
  }, [lijunAddress]);


  useEffect(() => {
    if (!clientLat && !clientLng) {
      navigator.geolocation.getCurrentPosition(
        (devicePos) => {
          console.log("----devicePos from navigator is: ", devicePos);
         

          if (geolocateControlRef.current) {
            geolocateControlRef.current.trigger();
          }
          // setClientLat(37.39042299272549);
          // setClientLng(-121.98847642041932);

          //wait for 2 seconds to get the client location
          // setTimeout(() => {}, 2000);

          setTimeout(() => {
            mapRef.current.jumpTo({
              center: [devicePos.coords.longitude, devicePos.coords.latitude],
              zoom: 18,
            });
          }, 2000);
      

          setClientLat(devicePos.coords.latitude);
          setClientLng(devicePos.coords.longitude);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [clientLat, clientLng]);

  useEffect(() => {
    // if ((clientLng || clientLat) && mapRef.current) {
    //   mapRef.current.flyTo({
    //     center: [clientLng, clientLat],
    //     zoom: 12,
    //   });
    // }
   
    //cal distance between client and entrance
    if (lijunAddress?.lng && lijunAddress?.lat && clientLat && clientLng) {
      const distance = Math.sqrt(Math.pow((clientLat - lijunAddress.lat), 2) + Math.pow((clientLng - lijunAddress.lng), 2));
      console.log("----distance is: ", distance);
      if (distance && distance > 0.001) {
        setTimeout(() => {
          const {longitude, latitude, zoom} = new WebMercatorViewport({width: window.innerWidth, height: window.innerHeight})
          .fitBounds([[lijunAddress.lng, lijunAddress.lat], [clientLng, clientLat]], {padding: 20});
          mapRef.current.flyTo({center: [longitude, latitude], zoom: zoom - 0.1});
        }, 3000);

      }else{
        setShowDistanceLine(false);
      }
    }
  }, [clientLat, clientLng]);


  // console.log("----zoom is: ", zoom);

  return (
    <ReactMapGL
      // initialViewState={{ latitude: obj.lat, longitude: obj.lng, zoom: 18}}
      initialViewState={{ latitude: clientLat, longitude: clientLng, zoom: 1}}
      mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      ref={mapRef}
      onZoomEnd={(e) => setZoom(Math.round(e.viewState.zoom))}
    >
      <GeolocateControl
        ref={geolocateControlRef}
        position="top-left"
        // style={geolocateControlStyle}
        positionOptions={{enableHighAccuracy: true}}
        trackUserLocation={true}
        showUserLocation={true}
        showUserHeading
        onGeolocate={(e) =>
          console.log("----e is: ", e.coords.latitude, e.coords.longitude)
        }
      />
      {lijunAddress && lijunAddress?.lng && lijunAddress?.lat && (<Marker
        key={`room-${lijunAddress?._id}`}
        longitude={lijunAddress?.lng}
        latitude={lijunAddress?.lat}
      >
        <Tooltip title={"Lijun's home :) "}>
          <Avatar
            src={lijunAddress.uPhoto}
            component={Paper}
            elevation={2}
            onClick={() => setPopupInfo(lijunAddress)}
          />
        </Tooltip>
      </Marker>
      )}
      {popupInfo && (
        <Popup
          longitude={popupInfo?.lng}
          latitude={popupInfo?.lat}
          maxWidth="auto"
          closeOnClick={false}
          focusAfterOpen={false}
          onClose={() => setPopupInfo(null)}
        >
          <PopupRoom {...{ popupInfo }} />
        </Popup>
      )}
      
      <Source 
        id={'route'} 
        type="geojson" 
        data={{
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "LineString",
            "coordinates": [
              [-122.01238360455221, 37.39375644445474],
              [-122.01220196117548 , 37.393722770309395],
              [-122.01175187118037, 37.3936259324296],
              [-122.01170795832144, 37.39358057803642],
              [-122.0118113, 37.3935413],
            ]
          }
        }}
      >
        
          <Layer
            id={'line'}
            type="line"
            paint={{
              "line-color": "#1E90FF",
              "line-width": 2,
            }}
          />
      
      </Source>

      <Source 
        id="polygon"
        type="geojson" 
        data={{
          type: "Feature",
          properties: {
            name: "parking"
          },
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-122.01267746885036, 37.39381972991774],
                [-122.01238407674852, 37.39376259896575],
                [-122.01238695314198, 37.39375117277024],
                [-122.01268034524347, 37.39380830373092],
                [-122.01267746885036, 37.39381972991774], // Repeat the first coordinate to close the polygon
              ]
            ]
          }
        }
        }>
        <Layer
          id="polygon-outline"
          type="line"
          paint={{
            'line-color': '#FFA07A',
            'line-width': 2,
            'line-dasharray': [2, 1], // This will create a dashed line with a 2:1 ratio
          }}
        />
      </Source>

      <Source 
        id="polygon1"
        type="geojson" 
        data={{
          type: "Feature",
          properties: {
            name: "parking1"
          },
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-122.01206048381829, 37.3936986109925],
                [-122.01185050711777, 37.39365519140267],
                [-122.0118548217078, 37.3936414799482],
                [-122.01206479840798, 37.393684899545946],
                [-122.01206048381829, 37.3936986109925], // Repeat the first coordinate to close the polygon
              ]
            ]
          }
        }
        }>
        <Layer
          id="polygon-outline1"
          type="line"
          paint={{
            'line-color': '#FFA07A',
            'line-width': 1.5,
            'line-dasharray': [3, 2], // This will create a dashed line with a 2:1 ratio
          }}
        />
      </Source>

      

      {zoom >= 18 && (
        <>
          <Marker 
          latitude={37.39378680089628} 
          longitude={-122.01254196928005} 
          draggable
          >
            <LocalParkingIcon
              style={{ fontSize: "small", color: "#FFA07A"}}
            />
        </Marker>

        <Marker 
          latitude={37.39366870905211} 
          longitude={-122.01195353177985} 
          draggable
          >
            <LocalParkingIcon
              style={{ fontSize: "small", color: "#FFA07A"}}
            />
        </Marker>

        <Marker 
          latitude={37.39377475160869} 
          longitude={-122.01248490393012} 
          draggable
          // onDragEnd={(e) => console.log("----lng and lat", e?.lngLat?.lng, e?.lngLat?.lat)}
          >
          <VideoCameraBackOutlinedIcon
            style={{ fontSize: "small", transform: 'rotate(18deg)'}}
          />
        </Marker>

        <Marker 
        latitude={37.393651196427385} 
        longitude={-122.01186932409826} 
        draggable
        >
          <VideoCameraBackOutlinedIcon
            style={{ fontSize: "small", transform: 'rotate(18deg)'}}
          />
        </Marker>

        <Marker 
        latitude={37.393608153054984} 
        longitude={-122.0116691978584 } 
        draggable
        >
          <VideoCameraBackOutlinedIcon
            style={{ fontSize: "small", transform: 'rotate(125deg)'}}
          />
        </Marker>
        </>
      )}

      {showDistanceLine && clientLat && clientLng && lijunAddress && (
        <Source
          type="geojson"
          data={{
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [clientLng, clientLat],
                [lijunAddress.lng, lijunAddress.lat]
              ]
            }
          }}
        >
          <Layer
            type="line"
            paint={{
              'line-color': '#888',
              'line-width': 4
            }}
          />
        </Source>
        
      )}
      { showDistanceLine && <div style={{position: 'absolute', right: 30, bottom: 80}}>
          <MapButton latitude={37.39378680089628} longitude={-122.01254196928005} />
        <div style={{marginTop: 10}}>
          <NavigationControl />
        </div>
      </div>}
          
    </ReactMapGL>
  );
};

export default ClientMap;
