import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Rating,
  Tooltip,
} from '@mui/material';
import { useValue } from '../../context/ContextProvider';
import { StarBorder } from '@mui/icons-material';
import { getRooms } from '../../actions/room';
import { useNavigate } from 'react-router-dom';

const LocationList = () => {
  const {
    state: { filteredRooms },
    dispatch,
  } = useValue();

  const navigate = useNavigate();

  useEffect(() => {
    if (filteredRooms.length === 0){
      getRooms(dispatch);
    }
  }, [filteredRooms]);

  const handleImageClick = (roomId, room) => { 
    dispatch({ type: 'UPDATE_ROOM', payload: room });
    navigate(`/locations/${roomId}`);
  };

  return (
    <Container>
      <ImageList
        gap={12}
        sx={{
          mt: 1,
          mb: 8,
          gridTemplateColumns:
            'repeat(auto-fill, minmax(280px, 1fr))!important',
        }}
      >
        {filteredRooms.map((room) => (
          <Card key={room._id} sx={{ maxHeight: 345 }}>
            <ImageListItem sx={{ height: '100% !important' }}>
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70%, rgba(0,0,0,0)100%)',
                }}
                // title={room.price === 0 ? 'Free' : '$' + room.price}
                title='$20'
                actionIcon={
                  <Tooltip title={room.uName} sx={{ mr: '5px' }}>
                    <Avatar src={room.uPhoto} />
                  </Tooltip>
                }
                position="top"
              />
              <img
                src={room.images[0]}
                alt={room.title}
                loading="lazy"
                style={{ cursor: 'pointer' }}
                onClick={() => handleImageClick(room._id, room)}
              />
              <ImageListItemBar
                title={room.title}
                // actionIcon={
                //   <Rating
                //     sx={{ color: 'rgba(255,255,255, 0.8)', mr: '5px' }}
                //     name="room-rating"
                //     defaultValue={3.5}
                //     precision={0.5}
                //     emptyIcon={
                //       <StarBorder sx={{ color: 'rgba(255,255,255, 0.8)' }} />
                //     }
                //   />
                // }
              />
            </ImageListItem>
          </Card>
        ))}
      </ImageList>
    </Container>
  );
};

export default LocationList;
