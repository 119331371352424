import React, { useEffect, useState } from 'react';
import {
    Stack,
    TextField,
    Button,
    Typography,
    Box,
    Radio,
    BpRadio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Container,
  } from '@mui/material';
  import { Cancel, Send } from '@mui/icons-material';
  import InfoField from '../base/InfoField';
  import CalendarDatePicker from './MultiDatePicker';
  import { useValue } from '../../context/ContextProvider';
  import { createBuddy } from '../../actions/buddy';
  import logo from '../base/icons/moo.jpeg';
  import { useNavigate } from 'react-router-dom';
  import {isWithinDistance} from '../helper';
  import Header from './Header';
  import EmptyState from './EmptyState';
  import LoadingAnimation from './LoadingAnimation';
  import CssBaseline from '@mui/material/CssBaseline';
  import LocationList from '../locations/LocationList'


  const AddBuddyDetails = () => {
    const navigate = useNavigate();
    const {
      state: {
        buddyDetails: { firstName, lastName, email, phone, dates, linkedIn, lat, lng, address, addressName, zipCode, hostName},
      },
      dispatch,
    } = useValue();

    const [isGeoLoading, setIsGeoLoading] = useState(true);
    const [isLead, setIsLead] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('CuernavacaCirculo'); // default location
    const [allowedAccessLocation, setAllowedAccessLocation] = useState(false);

    useEffect(() => {
      navigator.geolocation.getCurrentPosition(
        (devicePos) => {
          dispatch({
            type: 'UPDATE_BUDDY_DETAILS',
            payload: { lat: devicePos.coords.latitude, lng: devicePos.coords.longitude, allowedAccessLocation},
          });    
          setAllowedAccessLocation(true);
        },
        (error) => {
          console.log(error);
          const fetchIpLocation = async () => {
              const ipLocation = await fetch('https://ipapi.co/json');
              const data = await ipLocation.json();
              dispatch({
                type: 'UPDATE_BUDDY_DETAILS',
                payload: { lat: data.latitude, lng: data.longitude, allowedAccessLocation},
              });
          }
          fetchIpLocation();
        }
      );
    }, []);

    useEffect(() => {

      const fetchGeoInfo = async () => {
        const geoInfo = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`);
        const data = await geoInfo.json();
        const place = data.features[0]
        let zipCode;

        for (const feature of data.features) {
          if (feature.place_type && feature.place_type[0] === 'postcode') {
            zipCode = feature.text;  // or feature.place_name for more detailed information
          }
        }
        dispatch({
          type: 'UPDATE_BUDDY_DETAILS',
          payload: { address: place?.place_name, addressName: place?.text, zipCode: zipCode},
        });
      }

      //Todo: what if don't want to book, only intereted in joining?
      if (lat && lng) {
        fetchGeoInfo();
        /**
         * TODO: create API to fetch hosts that's close by to buddy current location 
         * api/place/nearbysearch/json?location=${latitude},${longitude}&radius=2000&type=${type}`;
         * for now, with only one host, Lijun, Saratoga,
         * only cal location distace, within 2000 meter, show, otherwise, disable choose location
         * Lijun - Toni Ann Place, Tamworth Avenue
         * 37.270067, -122.033905 
         * 
         * Lucy - Monta Vista 95015
         * 37.323039, -122.073641
         * 
         * Apoorva - Miramonte Avenue
         * 37.373160, -122.061988
         */
        let closeEnough = false;
        //const isCloseEnoughToLijun = isWithinDistance(lat, lng, 37.270067, -122.033905);
        //const isCloseEnoughToLucy = isWithinDistance(lat, lng, 37.323039, -122.073641);
        const isCloseEnoughToApoorva = isWithinDistance(lat, lng, 37.371599, -122.060406);

        if (isCloseEnoughToApoorva){
          closeEnough = true;
        }
        
        if (!closeEnough) {
          setIsLead(true);
        }else{
          navigate('/locations');
        }

        setIsGeoLoading(false);
          
      }
    }, [lat, lng])

    //sunset this page, only for lead gen, no actual booking from here
    const handleSubmit = () => {
      const buddyDetails = {
        firstName,
        lastName,
        email,
        dates,
        phone,
        linkedIn,
        lat,
        lng,
        zipCode,
        address,
        addressName,
        isLead,
      };
      createBuddy(buddyDetails, dispatch, isLead, navigate);
    };

    const handleLocationChange = (event) => {
      setSelectedLocation(event.target.value);
      console.log("Selected value:", event.target.value);
    };

    if (isGeoLoading){
      return (
        <Container maxWidth="lg">
          <CssBaseline />
          <LoadingAnimation />
        </Container>
      )
    }

    // if (!isLead){
    //   navigate('/locations');
    // }

    return (
    <Container maxWidth="sm">
      <Box
        sx={{
          bgcolor: 'background.paper',
          padding: '10px 32px',
          minWidth: 300,
          textAlign: 'center',
        }}
      >
        <Header />

        {isLead ? '' :
        <>
        <Box sx={{ color: 'text.secondary', fontSize: 18, fontWeight: 'regular', marginTop: '5px'}}>
          We open Mon-Fri 8am-6pm.
        </Box>

        <Box sx={{ color: 'text.secondary', fontSize: 18, fontWeight: 'regular', marginTop: '20px'}}>
          Sign up in 3 simple steps:
        </Box>
        

        <Box sx={{ color:'#0073d9', fontSize: 18, fontWeight: 'regular', marginTop: '30px'}}>
          Step 1: Pick couple dates (Optional)
        </Box>

        <Box sx={{ color: 'text.secondary',  marginTop: '20px', '& .rmdp-wrapper': { display: "inline-block", textAlign: 'center'}}}>
          <CalendarDatePicker 
            initialDates={dates}
          />
        </Box>
        
        <Box sx={{ color: '#0073d9', fontSize: 18, fontWeight: 'regular', marginTop: '30px'}}>
          Step 2: Pick a location (Optional)
        </Box>

        {/* Todo: add location into DB , a list of locations base on distance, cloest frist, if thurther than 5 miles, give me nothing*/}
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="CuernavacaCirculo"
            name="radio-buttons-group"
            sx={{ color: 'text.secondary', fontSize: 18, fontWeight: 'regular', marginTop: '20px'}}
            onChange={handleLocationChange}
          >
            <FormControlLabel value="CuernavacaCirculo" disabled={isLead} control={<Radio />} label="Cuernavaca Circulo (94040)" />
            <FormControlLabel value="MontaVista" disabled={isLead} control={<Radio />} label="Monta Vista (95015)" />
            <FormControlLabel value="TamworthAvenue" disabled={isLead} control={<Radio />} label="Tamworth Avenue (95070)" />
            <FormControlLabel value="ArcadiaTerrace" disabled control={<Radio />} label="Arcadia Terrace (94085)" />
          </RadioGroup>
        </FormControl>
        
        <Box sx={{ color:'#0073d9', fontSize: 18, fontWeight: 'regular', marginTop: '30px'}}>
           Step 3: Get connected!
        </Box>

        </>}

        {isLead ? <>
        <Box sx={{ color: '#0073d9', fontSize: 18, fontWeight: 'regular', marginTop: '20px'}}>
          Don’t have a location near you? 
        </Box>

        <Box sx={{ color: '#0073d9', fontSize: 18, fontWeight: 'regular', marginTop: '20px'}}>
          Join our waitlist
        </Box></> : ''}
        

        <Box sx={{ color: 'text.secondary', fontSize: 15, fontWeight: 'regular', marginTop: '20px'}}>
          <Stack spacing={{ xs: 1, sm: 1}} direction="row" >
            <InfoField 
              mainProps={{ name: 'firstName', label: 'First Name', value: firstName }}
              minLength={1}
              actionType={'UPDATE_BUDDY_DETAILS'}
            />
            <InfoField 
              mainProps={{ name: 'lastName', label: 'Last Name', value: lastName }}
              minLength={1}
              actionType={'UPDATE_BUDDY_DETAILS'}
            />
          </Stack>
          <Stack>
              <InfoField 
                mainProps={{ name: 'email', label: 'Email', value: email }}
                minLength={8}
                actionType={'UPDATE_BUDDY_DETAILS'}
                required={true}
                sx={{mt: 2}}
              />
              <Stack>
              <InfoField 
                mainProps={{ name: 'phone', label: 'Phone number', value: phone }}
                minLength={9}
                actionType={'UPDATE_BUDDY_DETAILS'}
                required={true}
                sx={{mt: 2}}
              />
          </Stack>
          </Stack>

          {isLead && allowedAccessLocation ? 
          <Stack>
              <InfoField 
                mainProps={{ name: 'zipCode', label: 'Zip Code', value: zipCode }}
                minLength={3}
                actionType={'UPDATE_BUDDY_DETAILS'}
                required={false}
                sx={{mt: 2}}
              />
          </Stack> : ''}
        </Box>

        <Button
          sx={{ marginTop: '20px'}}
          variant="contained"
          onClick={handleSubmit}
        >
          {isLead ? 'Submit' : 'Sign me up!'}
        </Button>

        <Box sx={{ color: '#0073d9', fontSize: 18, fontWeight: 'regular', marginTop: '50px'}}>
            Popular Workspaces:
        </Box>

        <LocationList />
        
      </Box>

    </Container>

    );
  };
  
  export default AddBuddyDetails;
  