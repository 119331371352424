import { Box } from '@mui/material';
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
  Source, 
  Layer,
} from 'react-map-gl';
import DrawControl  from '../../../mapbox/drawControl';
import ControlPanel from '../../../mapbox/ControlPanel';
import { useValue } from '../../../context/ContextProvider';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import Geocoder from './Geocoder';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';

const AddLocation = () => {
  const {
    state: {
      location: { lng, lat },
      parkings,
      path,
      currentUser,
    },
    dispatch,
  } = useValue();
  const mapRef = useRef();


  const fileInputRef = useRef(null);

  const [parkingSignPopup, setParkingsSignPopup] = useState(null);

  const onMarkerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
      // Process the file here (e.g., upload it to a server or display it on the map)
    }
  };

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent);
  }

  // useEffect(() => {
  //   const onPositionUpdate = (position) => {
  //     const { coords } = position;
  //     const { latitude, longitude, heading } = coords;
  //     console.log("User's position:", { latitude, longitude });
  //     console.log("User's heading:", heading);
  //   };
    
  //   const onError = (error) => {
  //     console.error("Error getting user's position:", error);
  //   };
    
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.watchPosition(onPositionUpdate, onError, {
  //       enableHighAccuracy: true,
  //     });
  //   } else {
  //     console.error("Geolocation API not available in this browser.");
  //   }
  // }, []);



  useEffect(() => {
    const storedLocation = JSON.parse(
      localStorage.getItem(currentUser.id)
    )?.location;
    if (!lng && !lat && !storedLocation?.lng && !storedLocation?.lat) {
      navigator.geolocation.getCurrentPosition(
        (devicePos) => {
          console.log("----devicePos from navigator is: ", devicePos);
          dispatch({
            type: 'UPDATE_LOCATION',
            payload: { lng: devicePos.coords.longitude, lat: devicePos.coords.latitude },
          });
        },
        (error) => {
          console.log(error);
          const fetchIpLocation = async () => {
              const ipLocation = await fetch('https://ipapi.co/json');
              const data = await ipLocation.json();
              dispatch({
                type: 'UPDATE_LOCATION',
                payload: { lng: data.longitude, lat: data.latitude},
              });
          }
          fetchIpLocation();
        }
      );
    }
  }, []);

  useEffect(() => {
    if ((lng || lat) && mapRef.current) {
      mapRef.current.jumpTo({
        center: [lng, lat],
      });
    }
  }, [lng, lat]);

  // const parkingsRef = useRef(parkings);

  // useEffect(() => {
  //   // parkingsRef.current = parkings;
  //   // if (parkings?.length > 0){
  //   //   displayNewParking(parkings[parkings.length - 1]);
  //   // }
  //   displayNewParking(parkings)
  // }, [parkings])

  const displayNewParking = parking => {
    console.log("---displayNewParking is called");
    if (parking && mapRef.current) {
        try {
        // Calculate the centroid of the polygon
        const [lng, lat] = turf.center(parking).geometry.coordinates;
        console.log("--displayNewParking--lng, lat is: ", lng, lat);
        // Set parkings sign popup
        setParkingsSignPopup({ longitude: lng, latitude: lat, text: 'Parking' });

      } catch (error) {
        console.error("Error calculating the center of the polygon:", error);
      }
    }
  };

  const onUpdate = useCallback((e) => {
    console.log("----------onUpdate is called: ", e);
    // const currentParkings = parkingsRef.current;
    
    if (e.features[0].geometry.type !== 'Polygon') {
      dispatch({
        type: 'UPDATE_PATH',
        payload: e.features[0],
      })
    }else{
      // let updatedParkings;

      // if (e.type === 'draw.create'){
      //   console.log("----------create parking: ");
      //   updatedParkings = [...currentParkings, e.features[0]];
      // }else if (e.type === 'draw.update'){
      //     updatedParkings = currentParkings.map(item => {
      //       if (item.polygon.id === e?.features[0]?.id) {
      //         return { ...item, geometry: e.features[0].geometry };
      //       }
      //       return item;
      //     });
      // }
      dispatch({
        type: 'UPDATE_PARKING',
        payload: e.features[0],
      })
    }
    
  }, [dispatch]);

  const onDelete = useCallback(e => {
    console.log("----------onDelete is called: ", e);
    if (e.features[0].geometry.type !== 'Polygon') {
      dispatch({
        type: 'UPDATE_PATH',
        payload: null,
      })
    }else{
      // const currentParkings = parkingsRef.current;
      // const newParkings = currentParkings.filter((item) => item.id !== e.features[0].id);
  
      // console.log("----------onDelete newParkings: ", newParkings);
      dispatch({
        type: 'UPDATE_PARKING',
        payload: null,
      })
      // Remove the polygon
      const map = mapRef.current.getMap();
      if (map.getSource('polygon')) {
        console.log("----------polygon");
        // map.removeLayer('polygon');
      }
    
      // Remove the parking sign marker and popup
      setParkingsSignPopup(null);
    }


  }, []);

  // const handleClick = async (event) => {
  //   event.preventDefault();
  //   const {lng, lat} = event?.lngLat;
  //   console.log('Clicked coordinates:', { lat, lng });
  // };


  return (
    <Box
      sx={{
        height: 700,
        position: 'relative',
      }}
    >
      <ReactMapGL
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: 18,
        }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        attributionControl={false}
        // onClick={handleClick}
        // touchAction="none"
      >
        <Marker
          latitude={lat}
          longitude={lng}
          draggable
          onDragEnd={(e) =>
            dispatch({
              type: 'UPDATE_LOCATION',
              payload: { lng: e.lngLat.lng, lat: e.lngLat.lat },
            })
          }
        />
        <NavigationControl position="bottom-right" />
        <Geocoder />
        <GeolocateControl
          position="top-left"
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
          showUserLocation
          showUserHeading
          onGeolocate={(e) =>
            dispatch({
              type: 'UPDATE_LOCATION',
              payload: { lng: e.coords.longitude, lat: e.coords.latitude },
            })
          }
        />
        <DrawControl
          position="top-left"
          displayControlsDefault={false}
          touchEnabled={true}
          controls={{
            line_string: true,
            polygon: true,
            trash: true
          }}
          // defaultMode={"draw_polygon"}
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
          parkings={parkings}
        />
        {parkingSignPopup && (
          <Marker
            longitude={parkingSignPopup.longitude}
            latitude={parkingSignPopup.latitude}
            draggable
          >
            <Popup
              longitude={parkingSignPopup.longitude}
              latitude={parkingSignPopup.latitude}
              closeButton={false}
              tipSize={5}
            >
              {parkingSignPopup.text}
            </Popup>
          </Marker>
        )}
         {parkings && 
         <Source id={'polygon'} type="geojson" data={parkings}>
          <Layer
            id={'line'}
            type="line"
            paint={{
              "line-color": "#1E90FF",
              "line-width": 3,
            }}
          />
        </Source>}
        {path && 
                      <Source
                      id="route"
                      type="geojson"
                      data={
                        path
                      //   {
                      //   type: 'Feature',
                      //   properties: {},
                      //   geometry: {
                      //     type: 'LineString',
                      //     coordinates: [
                      //       [-122.01289167314691, 37.39384734826467],
                      //       [-122.01134193814087, 37.39354907077147],
                      //     ],
                      //   },
                      // }
                      }>
                      <Layer
                      id="route"
                      type="line"
                      source="route"
                      layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                      }}
                      paint={{
                        'line-color': '#888',
                        'line-width': 2,
                      }}
                    />
                      </Source>
                    
          }
        {/* <Marker 
          latitude={37.393860987891415}  
          longitude={-122.01291345873462}
          draggable
          >
          <VideoCameraBackOutlinedIcon fontSize="small" onClick={onMarkerClick}/>
        </Marker> */}

      </ReactMapGL>
        {isMobileDevice() ? <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      /> :  <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />}
     
      {/* <ControlPanel polygons={Object.values(features)} /> */}
    </Box>
  );
};

export default AddLocation;
