import { Calendar, DateObject } from "react-multi-date-picker"

function toRadians(degrees) {
    return degrees * (Math.PI / 180);
}

function isWithinDistance(userLat, userLon, hostLat, hostLon, distance = 2000) {
    const R = 6371e3; // Earth radius in meters
    const φ1 = toRadians(userLat);
    const φ2 = toRadians(hostLat);
    const Δφ = toRadians(hostLat - userLat);
    const Δλ = toRadians(hostLon - userLon);

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
              
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const actualDistance = R * c;

    return actualDistance <= distance;
}

function convertTimestampsToDates(timestamps) {
    const result = timestamps?.map(timestamp => {
      const date = new Date(Number(timestamp));
      return date.toLocaleDateString();
    });
    return result;
}

function getNextBusinessDay() {
  const today = new Date();
  const dayOfWeek = today.getDay();

  // If it's Saturday (6), add 2 days to get to Monday
  if (dayOfWeek === 6) {
    today.setDate(today.getDate() + 2);
  }
  // If it's Sunday (0), add 1 day to get to Monday
  else if (dayOfWeek === 0) {
    today.setDate(today.getDate() + 1);
  }
  // For weekdays, it simply returns the current date

  return new DateObject({ date: today });
}


function isWeekend() {
    const today = new Date().getDay();
    return today === 0 || today === 6;  // 0 is Sunday, 6 is Saturday
}
  

export {
    isWithinDistance, 
    convertTimestampsToDates, 
    getNextBusinessDay,
    isWeekend,
};