import {
    Stack,
    TextField,
    Button,
    Box,
    Radio,
    BpRadio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Container,
    Typography,
  } from '@mui/material';
  import { Cancel, Send } from '@mui/icons-material';
  import * as React from 'react';
  import { Link, useNavigate } from 'react-router-dom';
  import Header from './buddySignUp/Header';

  const LandingPage = () => {
    const navigate = useNavigate();

    return (
    <Container maxWidth="md">
      <Box
        sx={{
            bgcolor: 'background.paper',
            padding: '10px 32px',
            minWidth: 300,
            textAlign: 'center',
          }}
        >
        <Header />

        <Box sx={{ color: 'text.secondary', fontSize: 18, fontWeight: 'regular', marginTop: "-10px"}}>
          <Typography>
            Welcome to our MooMoo
          </Typography> 
        </Box>
        <Box sx={{ color: 'text.primary', fontSize: 23, fontWeight: '530'}}>
          <Typography>
          COWork community!
          </Typography>
        </Box>
       

        <Box sx={{ color: 'text.secondary', fontSize: 18, fontWeight: 'regular', marginTop: '30px'}}>
          <Typography>
          We love COWorking at our neighbor’s house to avoid distractions, stay productive, and connect with like minded professionals.
          </Typography>
        </Box>

        <Container maxWidth="sm">
            {/* <Box sx={{ marginTop: '40px', textAlign: 'left', color: '#ff009d', marginLeft: '10%'}} >
            <Typography> *First session is FREE!</Typography>
            </Box> */}
            
            <Button 
                sx={{ marginTop: '40px'}} 
                variant="outlined" 
                size="large"
                onClick={() => navigate('buddy')}
                >
                guest booking
            </Button>

            <Box sx={{ color: 'text.secondary', fontSize: 15, fontWeight: 'regular', marginTop: '10px'}}>
              <Typography>
                Working from home but can't focus? Make a little change, leave the distractions in your house behind, and go cowork at your neighbor’s place!  Plus, you get to meet your cowork buddies and support one another.
              </Typography>
            </Box>

            <Button 
                sx={{ marginTop: '40px'}} 
                variant="outlined" 
                size="large">
                host sign up
            </Button>

            <Box sx={{ color: 'text.secondary', fontSize: 15, fontWeight: 'regular', marginTop: '10px' }}>
            <Typography>
              Rent out your living room workspaces or home office, and start earning right the way. Through hosting your neighbors, you are further strengthening our supportive MooMoo COWork community.
            </Typography>
            </Box>

        </Container>
      </Box>

    </Container>

    );
  };
  
  export default LandingPage;
  