import React from 'react';
import logo from '../base/icons/moo.jpeg';
import {
    Box,
  } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Link to='/'>
                    <img src={logo} alt="logo"  style={{ height: 40 }} />
                </Link>  
            </Box>
        </div>
    );
}

export default Header;