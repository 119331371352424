import fetchData from './utils/fetchData';
import {isWeekend, getNextBusinessDay} from '../components/helper';

const url = process.env.REACT_APP_SERVER_URL + '/buddy';
export const createBuddy = async (buddy, dispatch, isLead = false, navigate) => {
  dispatch({ type: 'START_LOADING' });

  const {firstName, lastName, phone, email, dates} = buddy;
  if (!phone && !email){
    dispatch({ type: 'END_LOADING' });
    return;
  }
  const result = await fetchData(
    { url, body: buddy },
    dispatch
  );
  console.log("---buddy create result is: ", result)
  if (result) {
    dispatch({ type: 'UPDATE_BUDDY', payload: result });

    if (isLead){
      navigate('lead_confirmation')
    }else{
      navigate('/booking/book_confirmation')
    }

  }

  dispatch({ type: 'END_LOADING' });
  dispatch({
    type: 'UPDATE_BUDDY_DETAILS',
    payload: {
      _id: '',
      firstName: '', 
      lastName: '', 
      phone: null, 
      email: '', 
      dates: [getNextBusinessDay()],  //booking dates
      linkedIn: '', 
      lat: '', 
      lng: '',
      allowedAccessLocation: true,
      zipCode: '',
      address: '',
      addressName: '',
      isLead: '',
      locationId: '',
      total: 20,
      paymentMethod: 'cash',
      hostName: '' 
    },
  });
};

export const updateBuddy = async (buddyId, updatedBuddy, dispatch, navigate) => {
  dispatch({ type: 'START_LOADING' });

  const { firstName, lastName, phone, email, dates } = updatedBuddy;

  if (!phone && !email) {
    dispatch({
      type: 'UPDATE_ALERT',
      payload: { open: true, severity: 'error', message: 'Please enter email or phone number' },
    });
    dispatch({ type: 'END_LOADING' });
    return;
  }

  const updateUrl = `${url}/${buddyId}`;

  const result = await fetchData(
    { url: updateUrl, method: 'PUT', body: updatedBuddy },
    dispatch
  );

  if (result) {
    dispatch({ type: 'UPDATE_BUDDY', payload: result });
    navigate('/'); 
  }

  dispatch({ type: 'END_LOADING' });
};

export const getBuddies = async (dispatch) => {
  dispatch({ type: 'START_LOADING' });
  const result = await fetchData({ url, method: 'GET' }, dispatch);
  console.log("---get-buddies-result is: ", result);
  if (result) {
    dispatch({ type: 'UPDATE_BUDDIES', payload: result });
  }
  dispatch({ type: 'END_LOADING' });
};

export const deleteBuddy = async (buddyId, dispatch, navigate) => {
  dispatch({ type: 'START_LOADING' });

  const deleteUrl = `${url}/${buddyId}`; 

  const result = await fetchData(
    { url: deleteUrl, method: 'DELETE' },
    dispatch
  );

  if (result) { 
    dispatch({ type: 'DELETE_BUDDY', payload: buddyId });

  } else {
    dispatch({
      type: 'UPDATE_ALERT',
      payload: { open: true, severity: 'error', message: 'Failed to delete buddy' },
    });
  }

  dispatch({ type: 'END_LOADING' });
};
