import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useValue } from '../../context/ContextProvider';
import { updateBuddy } from '../../actions/buddy';
import InfoField from '../base/InfoField';
import {convertTimestampsToDates} from '../helper';
import Header from './Header';

const BuddyAddition = () => {

  const {
    state: {
      buddyDetails: {firstName, lastName, email, phone, linkedIn, hostAddress, hostName},
      buddy: {_id, dates},
    },
    dispatch,
  } = useValue();

  const navigate = useNavigate();

  const handleSubmit = () => {
    const buddyDetails = {
      firstName,
      lastName,
      email,
      dates,
      phone,
      linkedIn,
    };
    updateBuddy(_id, buddyDetails, dispatch, navigate);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 32px',
          minWidth: 300,
        }}
      >
        <Header />
        <Typography sx={{color: 'text.secondary', fontSize: 18, fontWeight: 'regular', marginTop: '20px'}}>
          Thank you! Please provide your phone number and LinkedIn profile for our host, Lucy, so that she can provide the exact location details and verify you on the day of visit.
        </Typography>
        <InfoField 
          mainProps={{ name: 'phone', label: 'Phone number', value: phone }}
          minLength={9}
          actionType={'UPDATE_BUDDY_DETAILS'}
          required={false}
          sx={{mt: 2}}
        />
        <InfoField 
          mainProps={{ name: 'linkedIn', label: 'LinkedIn profile', value: linkedIn }}
          minLength={10}
          actionType={'UPDATE_BUDDY_DETAILS'}
          required={false}
          sx={{mt: 2}}
        />
        <Box sx={{
          mt: 2
        }}>
          <Typography sx={{ color: '#0073d9', fontSize: 18, fontWeight: 'regular'}}>
          Here is your booking details:
          </Typography >
          <Typography sx={{color: 'text.secondary', fontSize: 15, fontWeight: 'regular', marginTop: '10px'}}>
            Dates: {convertTimestampsToDates(dates)?.join(', ') || 'No detes selected'}
          </Typography>
          <Typography sx={{color: 'text.secondary', fontSize: 15, fontWeight: 'regular', marginTop: '10px'}}>
            Location: Monta Vista (95015)
          </Typography>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          width: '100%', 
          mt: 3 
        }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default BuddyAddition;
