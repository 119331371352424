import { Container, Box, Stack, Typography, Button, Divider, IconButton, AppBar, Toolbar, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useValue } from '../../context/ContextProvider';
import { forwardRef, useEffect, useState } from 'react';
import { getRoomById } from '../../actions/room';
import InfoField from '../base/InfoField';
import { createBuddy } from '../../actions/buddy';


const BookingAndPay = () => {
    const navigate = useNavigate();
    const {
        state: {
            room: {
                lat: locLat, lng: locLng, title, images, price,
                uName, uid, uPhoto, description, rentalSpace, rentalLocation, createdAt, _id
            },
            buddyDetails: {
                firstName, lastName, email, phone, dates, linkedIn,
                lat, lng, address, addressName, zipCode, 
                hostName, allowedAccessLocation, total, paymentMethod,
            },
        },
        dispatch,
    } = useValue();

    // const [paymentMethod, setPaymentMethod] = useState('cash');
    const [isScrolled, setIsScrolled] = useState(false);

    const imageUrl = Array.isArray(images) ? images[0] : images;

    const { roomId } = useParams(); 
  
    useEffect(() => {
      if (!imageUrl && roomId) {
        getRoomById(dispatch, roomId)
      }
    }, [roomId, imageUrl]);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.pageYOffset;
            setIsScrolled(offset > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    

    const handlePaymentMethodChange = (event) => {
        dispatch({
            type: 'UPDATE_BUDDY_DETAILS',
            payload: { paymentMethod: event.target.value},
          });   
    };

    const boxStyle = (optionValue) => ({
        border: paymentMethod === optionValue ? '2px solid #333' : '1px solid #ccc',
        borderRadius: optionValue === 'cash' ? '6px 6px 0 0' : '0 0 6px 6px',
        padding: '16px',
    });

    const handleSubmit = () => {
        const buddyDetails = {
          firstName,
          lastName,
          email,
          dates,
          phone,
          linkedIn,
          lat,
          lng,
          zipCode,
          address,
          addressName,
          allowedAccessLocation,
          locationId: roomId,
          paymentMethod,
          hostName: uName,
          total,
        };
        createBuddy(buddyDetails, dispatch, false, navigate);
        //clear up buddyDetails in ContextProvider 
      };

    return (
    <Container sx={{ padding: '0' }}>
        <Box sx={{ padding: '0 15 15 15' }}>
            <AppBar position="fixed" 
            sx={{ 
                backgroundColor: 'white', 
                boxShadow: 'none', 
                zIndex: (theme) => theme.zIndex.drawer + 1,
                boxShadow: isScrolled ? '0px 2px 4px -1px rgba(0,0,0,0.1)' : 'none', 
                }}>
                <Toolbar>
                    <IconButton 
                        edge="start" 
                        aria-label="back" 
                        onClick={() => navigate(`/locations/${roomId}`)}
                        sx={{ 
                            color: '#222222',
                        }}
                    >
                        <ArrowBackIosIcon sx={{ fontSize: '16px' }}/>
                    </IconButton>
                    <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center', color: 'black', fontSize: '16px' }}>
                        Confirm 
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box display="flex" sx={{padding: '16px', mt: 8}}>
                <Box sx={{ flex: '0 0 140px', height: 90, overflow: 'hidden' }}>
                    <img src={imageUrl} alt="Location" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px'}} />
                </Box>
                <Box ml={2} sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}> {/* This Box will take the remaining space and distribute contents */}
                    <div>
                        <Typography sx={{ fontSize: '12px', fontWeight: 220, color: 'grey'}}>{rentalSpace}</Typography>
                        <Typography sx={{ fontSize: '14px' }}>{rentalLocation}</Typography>
                    </div>
                    <Box display="flex" alignItems="center">
                        <FavoriteIcon rating={5.00} sx={{fontSize: '12px'}}/> 
                        <Typography sx={{ fontSize: '12px', ml: 0.5 }}>5.00</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '80', color: 'grey', ml: 0.3 }}>(2)</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>

        <Divider variant="fullWidth" sx={{height: '8px', backgroundColor: 'rgb(235, 235, 235)', width: '100%' , borderStyle: 'none'}} />

        <Box sx={{ padding: '10 15 15 15' }}>
            <Box p={2}>
                <Typography sx={{fontSize: '20px', fontWeight: '420px'}}>Your Stay</Typography>
               
                <Typography sx={{marginTop: '15px', fontSize: '15px'}}>{Array.isArray(dates) ? dates.join(', ') : dates}</Typography>
            </Box>
        </Box>

        <Divider variant="fullWidth" sx={{height: '8px', backgroundColor: 'rgb(235, 235, 235)', width: '100%' , borderStyle: 'none'}} />

        <Box sx={{ padding: '10px 15px 15px 15px' }}>
            <Box p={2}>
                <Typography sx={{ fontSize: '20px', fontWeight: 420 }}>Choose how to pay</Typography>

                <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange} sx={{ mt: 2 }}>
                    <Box sx={boxStyle('cash')}>
                        <FormControlLabel
                            value="cash"
                            control={<Radio sx={{ '&.MuiRadio-root': { color: 'black', marginLeft: '20px' } }} />}
                            label={
                                <Box>
                                    <Typography sx={{ fontWeight: '410' }}>Pay by Cash</Typography>
                                    <Typography sx={{ fontWeight: '380' }}>Host will collect cash when arrive</Typography>
                                </Box>
                            }
                        />
                    </Box>
                    
                    <Box sx={boxStyle('venmo')}>
                        <FormControlLabel
                            value="venmo"
                            control={<Radio sx={{ '&.MuiRadio-root': { color: 'black', marginLeft: '20px' } }} />}
                            label={
                                <Box>
                                    <Typography sx={{ fontWeight: '410' }}>Pay by Venmo</Typography>
                                    <Typography sx={{ fontWeight: '380' }}>Host will share Venmo info</Typography>
                                </Box>
                            }
                        />
                    </Box>
                </RadioGroup>
            </Box>
        </Box>
       
        <Divider variant="fullWidth" sx={{height: '8px', backgroundColor: 'rgb(235, 235, 235)', width: '100%' , borderStyle: 'none'}} />
        
        <Box sx={{ padding: '10 15 15 15' }}>
            <Box p={2}>
                <Typography sx={{fontSize: '20px', fontWeight: '420px'}}>Your Total</Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}> 
                    <Typography sx={{marginTop: '16px', fontSize: '15px'}}>1 day</Typography>
                    <Typography sx={{marginTop: '16px', fontSize: '15px'}}>$20</Typography>
                </Box>
            </Box>
        </Box>

        <Divider variant="fullWidth" sx={{height: '8px', backgroundColor: 'rgb(235, 235, 235)', width: '100%' , borderStyle: 'none'}} />

        <Box sx={{ padding: '10 15 0 15' }}>
            <Box p={2}>
                <Typography sx={{fontSize: '20px', fontWeight: '420px'}}>Your Info</Typography>
                <Stack spacing={{ xs: 1, sm: 1}} direction="row" sx={{marginTop: '16px'}}>
                    <InfoField 
                    mainProps={{ name: 'firstName', label: 'First Name', value: firstName }}
                    minLength={3}
                    actionType={'UPDATE_BUDDY_DETAILS'}
                    />
                    <InfoField 
                    mainProps={{ name: 'lastName', label: 'Last Name', value: lastName }}
                    minLength={3}
                    actionType={'UPDATE_BUDDY_DETAILS'}
                    />
                </Stack>
                <Stack>
                    <InfoField 
                        mainProps={{ name: 'email', label: 'Email', value: email }}
                        minLength={8}
                        actionType={'UPDATE_BUDDY_DETAILS'}
                        required={true}
                        sx={{mt: 2}}
                    />
                   <InfoField 
                        mainProps={{ name: 'phone', label: 'Phone number', value: phone }}
                        minLength={9}
                        actionType={'UPDATE_BUDDY_DETAILS'}
                        required={true}
                        sx={{mt: 2}}
                    />
                    <InfoField 
                        mainProps={{ name: 'linkedIn', label: 'LinkedIn profile', value: linkedIn }}
                        minLength={10}
                        actionType={'UPDATE_BUDDY_DETAILS'}
                        required={true}
                        sx={{mt: 2}}
                    />
                </Stack>
            </Box>
        </Box>

        <Box sx={{ padding: '0 15 15 15' }}>
            <Box p={2}>
                <Button 
                    variant="contained" 
                    size="large"  
                    sx={{ 
                        minWidth: '110px', 
                        borderRadius: '8px', 
                        backgroundColor: '#ff5465',
                        textTransform: 'none',
                        color: '#fff',             
                        fontSize: '1.1rem',
                        '&:hover': {
                            backgroundColor: '#ff5a60',
                        },
                        width: '100%',
                        padding: '12px 0',  // Adjust the vertical padding here
                    }}
                    onClick={handleSubmit}
                >
                    Confirm 
                </Button>
            </Box>
        </Box>
        

    </Container>
    );
}

export default BookingAndPay;
