import {
    Avatar,
    Box,
    Container,
    Dialog,
    IconButton,
    Rating,
    Slide,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    Divider,
    Button,
  } from '@mui/material';
  import { forwardRef, useEffect, useState } from 'react';
  import { useValue } from '../../context/ContextProvider';
  import { Link, useNavigate } from 'react-router-dom';
  import ImageList from '@mui/material/ImageList';
  import ImageListItem from '@mui/material/ImageListItem';
  import Modal from '@mui/material/Modal';
  import { Close, StarBorder, ArrowForwardIos, ArrowBackIos} from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, EffectCoverflow, Lazy, Zoom, Pagination, Keyboard } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/zoom';
  
function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=scale&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=scale&auto=format&dpr=2 2x`,
    };
}

const transformImagesToItemData = (images) => {
    return images.map((imgUrl, index) => ({
        img: imgUrl,
        title: `Image ${index + 1}`,
        rows: 1,
        cols: index % 3 === 0 ? 2 : 1,
    }));
};

const ImageGalleryModal = ({ images, isOpen, handleClose }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const itemData = transformImagesToItemData(images);

    const Transition = forwardRef((props, ref) => {
        return <Slide direction="up" {...props} ref={ref} />;
    });

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    if (!isOpen) return null;

    return (
        <Dialog
            fullScreen
            open={Boolean(images.length)}
            onClose={() => { handleClose(); setSelectedImageIndex(null); }}
            TransitionComponent={Transition}
            sx={{ backgroundColor: selectedImageIndex !== null ? 'rgba(0, 0, 0, 0.8)' : 'transparent' }}
        >
            <IconButton 
                color="inherit" 
                onClick={() => { handleClose(); setSelectedImageIndex(null); }}
                sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    zIndex: 10,
                    width: 48,
                    height: 48,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    '&:hover': {
                        backgroundColor: '#e0e0e0',
                    }
                }}
            >
                <Close />
            </IconButton>
            {/* TODO: display single image in itemData base on selectedImageIndex */}
            {selectedImageIndex !== null ? (
                <Swiper
                    allowTouchMove={true}
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={{
                        type: 'fraction',
                    }}
                    modules={[Keyboard, Pagination, Navigation]}
                    keyboard={{
                      enabled: true,
                    }}
                    navigation={true}
                    style={{ width: '100%', height: '100%' }} // Ensure Swiper has dimensions
                >
                    {itemData.map((item, index) => (
                        <SwiperSlide key={item.img}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <img
                                    src={item.img}
                                    alt={item.title}
                                    style={{ maxWidth: '80%', maxHeight: '80%', objectFit: 'contain' }}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <ImageList sx={{ width: '80%', height: 'auto', margin: 'auto' }} variant="quilted" cols={2} rowHeight={'auto'}>
                    {itemData.map((item, index) => (
                        <ImageListItem key={item.img} cols={item.cols} rows={item.rows} onClick={() => handleImageClick(index)}>
                            <img
                                {...srcset(item.img, 121, item.rows, item.cols)}
                                alt={item.title}
                                loading="lazy"
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            )}
        </Dialog>
    );
};

export default ImageGalleryModal;