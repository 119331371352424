import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import Home from './pages/Home';
import Loading from './components/Loading';
import Notification from './components/Notification';
import ClientMap from './components/map/ClientMap';
// import MeetupMap from './components/map/MeetupMap';
// import AddRequest from './components/AddRequest';
// import SelfBot from './components/SelfBot';
import AddBuddyDetails from './components/buddySignUp/AddBuddyDetails';
import LandingPage from './components/LandingPage';
import LeadConfirmation from './components/buddySignUp/LeadConfirmation';
import BuddyAddition from './components/buddySignUp/BuddyAddition';
import BuddyList from './components/buddySignUp/BuddyList';
// import LoadingAnimation from './components/buddySignUp/LoadingAnimation';
// import Rooms from './components/rooms/Rooms';
// import Room from './components/rooms/Room';
import LocationList from './components/locations/LocationList';
import LocationDetail from './components/locations/LocationDetail';
import LocationMap from './components/locations/LocationMap';
import BookingAndPay from './components/booking/BookingAndPay';
import BookConfirmation from './components/booking/bookingConfirmation';

const App = () => {
  return (
    <>
      <Loading />
      <Notification />
      <BrowserRouter>
        <Routes>
          <Route path="dashboard/*" element={<Dashboard />} />
          <Route path="old" element={<Home />} />
          <Route path="lijun" element={<ClientMap />} />
          {/* <Route path="end" element={<AddRequest />} />
          <Route path="map" element={<MeetupMap />} />
          <Route path="self" element={<SelfBot />} /> */}
          {/* for the Patriarchy and Capitalism */}
          <Route path="buddy" element={<AddBuddyDetails />} />
          <Route path="*" element={<LandingPage />} />
          <Route path="home" element={<LandingPage />} />
          <Route path="buddy/lead_confirmation" element={<LeadConfirmation />} />
          <Route path="buddy/buddy_addition" element={<BuddyAddition />} />
          
          {/* <Route path="load" element={<LoadingAnimation />} /> */}
          <Route path="locations" element={<LocationList />} />
          <Route path="locations/:roomId" element={<LocationDetail />} />
          <Route path="locations/:roomId/map" element={<LocationMap />} />
          {/* <Route path="locations/:roomId/flyer" element={<LocationDetail />} />  */}
          <Route path="booking/:roomId" element={<BookingAndPay />} />
          <Route path="booking/book_confirmation" element={<BookConfirmation />} />

          <Route path="signups_internal_use_only" element={<BuddyList />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
