import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import reducer from './reducer';
import { polygon } from '@turf/turf';
import {isWeekend, getNextBusinessDay} from '../components/helper';

//TODO: as states grows, we need to integrate Redux or easy-peasy to manage states, reduce re-rendering of components.
const initialState = {
  currentUser: null,
  openLogin: false,
  loading: false,
  alert: { open: false, severity: 'info', message: '' },
  profile: { open: false, file: null, photoURL: '' },
  images: [],
  details: { title: '', description: '', price: 0 },
  location: { lng: 0, lat: 0 },
  // parkings : [{polygon: null, description: null, directionImages: []}],
  parking: { polygon: null, description: null, directionImages: [] },
  path: null,
  directionImages: [],
  updatedRoom: null,
  deletedImages: [],
  addedImages: [],
  rooms: [],
  priceFilter: 50,
  addressFilter: null,
  filteredRooms: [],
  room: {
    lat: '',
    lng: '',
    title: '',
    images: [],
    price: '',
    uName: '',
    uid: '',
    uPhone: '',
    description: '',
    openHours: '',
    rentalLocation: '',
    rentalSpace: '',
    createdAt: '',
    _id: '',
  },
  users: [],
  section: 0,
  generateResult: null,
  // TODO: split buddyDetails into buddy, host, location, booking
  buddyDetails: {
    _id: '',
    firstName: '', 
    lastName: '', 
    phone: null, 
    email: '', 
    dates: [getNextBusinessDay()],  //booking dates
    linkedIn: '', 
    lat: '', 
    lng: '',
    allowedAccessLocation: true,
    zipCode: '',
    address: '',
    addressName: '',
    isLead: '',
    locationId: '',
    total: 20,
    paymentMethod: 'cash',
    hostName: '',
  },
  buddy: {_id: '123'},
  buddies: [], 
  booking: {
    locationId: '',
    dates: [],
    buddyId: '',
    total: '',
  }
};

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const mapRef = useRef();//waht is this for?
  const containerRef = useRef();//waht is this for?
  useEffect(() => {
    //user info should not be stored in localStorage, move to Redis, call backend
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      dispatch({ type: 'UPDATE_USER', payload: currentUser });
    }
  }, []);

  useEffect(() => {
    if (state.currentUser) {
      //it should be stored in redis, and fetched from backend
      const room = JSON.parse(localStorage.getItem(state.currentUser.id));
      if (room) {
        dispatch({ type: 'UPDATE_LOCATION', payload: room.location });
        dispatch({ type: 'UPDATE_DETAILS', payload: room.details });
        dispatch({ type: 'UPDATE_IMAGES', payload: room.images });
        dispatch({ type: 'UPDATE_UPDATED_ROOM', payload: room.updatedRoom });
        dispatch({
          type: 'UPDATE_DELETED_IMAGES',
          payload: room.deletedImages,
        });
        dispatch({ type: 'UPDATE_ADDED_IMAGES', payload: room.addedImages });
      }
    }
  }, [state.currentUser]);
  return (
    <Context.Provider value={{ state, dispatch, mapRef, containerRef }}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
