import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

const jump = keyframes`
  0%, 100% {
    transform: translateY(0) scaleY(1);
  }
  20%, 80% {
    transform: translateY(-20px) scaleY(1.1);  // Stretched vertically in the air
  }
  40%, 60% {
    transform: translateY(0) scaleY(0.8);  // Squashed on the ground
  }
`;

export default function LoadingAnimation() {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" height="100vh" justifyContent="center" position="relative">
      <Box
        sx={{
          width: 15,
          height: 15,
          backgroundColor: '#0073d9',
          borderRadius: '50%',
          animation: `${jump} 1.5s infinite`,
          position: 'relative',
        }}
      />
      <Typography variant="h6" color="textSecondary" sx={{ color:'#0073d9', fontSize: 18, marginLeft: '8px', fontWeight: 'light'}}>
        Loading...
      </Typography>
    </Box>
  );
}
