import React, { useEffect, useState } from 'react';
import { Container, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getBuddies, deleteBuddy } from '../../actions/buddy';
import { useValue } from '../../context/ContextProvider';
import { Link, useNavigate } from 'react-router-dom';
import {convertTimestampsToDates} from '../helper';

const BuddyList = () => {
    const {
        state: { buddies },
        dispatch,
        mapRef,
    } = useValue();

    useEffect(() => {
        getBuddies(dispatch);
    }, []);

    const navigate = useNavigate();

//   const handleUpdate = (buddyId) => {
//     // Implement the update functionality here.
//     // Typically, you'll want to redirect the user to an 'Edit Buddy' form.
//   };

  const handleDelete = async (buddyId) => {
    deleteBuddy(buddyId, dispatch,navigate)
  };


  
  

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Sign Up List (Total: {`${buddies.length}`})
      </Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Dates</TableCell>
              <TableCell>LinkedIn</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Zip Code</TableCell>
              <TableCell>Address Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buddies.map(buddy => (
              <TableRow key={buddy._id}>
                <TableCell>{buddy.firstName ? buddy.firstName : buddy.fristName}</TableCell>
                <TableCell>{buddy.lastName}</TableCell>
                <TableCell>{buddy.email}</TableCell>
                <TableCell>{buddy.phone}</TableCell>
                
                <TableCell>{convertTimestampsToDates(buddy.dates).join(', ')}</TableCell>
                <TableCell>
                  {buddy?.linkedIn ? <a href={buddy.linkedIn} target="_blank" rel="noopener noreferrer">LinkedIn Profile</a> : ''}
                </TableCell>
                <TableCell>{new Date(buddy.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{buddy.zipCode}</TableCell>
                <TableCell>{buddy.addressName}</TableCell>
                <TableCell>{buddy.address}</TableCell>
                <TableCell>
                  {/* <IconButton onClick={() => handleUpdate(buddy._id)} color="primary">
                    <EditIcon />
                  </IconButton> */}
                  <IconButton onClick={() => handleDelete(buddy._id)} color="secondary">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default BuddyList;
