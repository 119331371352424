import { Avatar, InputAdornment, TextField } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useValue } from '../../context/ContextProvider';
import pendingIcon from './icons/progress1.svg';
import { Check } from '@mui/icons-material';

const InfoField = ({
    mainProps,
    optionalProps = {},
    minLength,
    actionType,
    required = true,
    sx,
}) => {
  const { dispatch } = useValue();
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const timer = useRef(null);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    dispatch({
      type: actionType,
      payload: { [e.target.name]: inputValue },
    });

    if (!editing) setEditing(true);

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setEditing(false);
      const isValid = inputValue.length >= minLength;
      setError(!isValid);
      setSuccess(isValid);
    }, 1000);
  };

  useEffect(() => {
    return () => clearTimeout(timer.current);  // Clean up the timer
  }, []);

  return (
    <TextField
      sx={{
        textAlign: 'center',
        flexGrow: 1,
        ...sx
      }}
      {...mainProps}
      error={error}
      helperText={error && `This field must be ${minLength} characters or more`}
      color={success ? 'success' : 'primary'}
      variant="outlined"
      onChange={handleChange}
      required={required}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {editing ? (
              <Avatar src={pendingIcon} sx={{ height: 70 }} />
            ) : (
              success && <Check color="success" />
            )}
          </InputAdornment>
        ),
      }}
      {...optionalProps}
    />
  );
};

export default InfoField;
