import React, { useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { useValue } from '../../context/ContextProvider';
    // new DateObject(),
    // new DateObject().add(2, "days"),
export default function CalendarDatePicker({ initialDates = [] }) {
    const [value, setValue] = useState(initialDates)
    const { dispatch } = useValue();

    const handleDateChange = (newValue) => {
        setValue(newValue);
        dispatch({
            type: 'UPDATE_BUDDY_DETAILS',
            payload: { dates: newValue},
        });
    };

    return (
        <Calendar
            value={value} 
            onChange={handleDateChange} 
            plugins={value.length > 0 ? [
                <DatePanel sort="date" />
            ]: []}
            multiple={true}
            shadow={false}
            highlightToday={true}
            style={{
              }}
            mapDays={({ date, today }) => {
                let isWeekend = [0, 6].includes(date.weekDay.index)
                
                if (isWeekend) return {
                    disabled: true,
                    style: { color: "#ccc" },
                    onClick: () => {
                        dispatch({
                            type: 'UPDATE_ALERT',
                            payload: { open: true, severity: 'info', message: 'weekends are disabled' },
                          });
                    }
                }

                 //disable past dates
                let isPastDate = date.toDays() < today.toDays();

                if (isPastDate) return {
                    disabled: true,
                    onClick: () => {
                        dispatch({
                            type: 'UPDATE_ALERT',
                            payload: { open: true, severity: 'info', message: 'Can not select past dates' },
                          });
                    }
                }
            }}
        />
    )
}
