function MapButton({ latitude, longitude }) {
    const handleClick = () => {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
      window.open(url, '_blank');
    };
  
    return (
      <button 
        onClick={handleClick}
        style={{ 
          backgroundColor: "#1E90FF", 
          borderRadius: '4px',
          padding: '8px',
          cursor: 'pointer',
          border: 'none',
        }}
      >
        Head to Lijun's parking lot
      </button>
    );
  }

  export default MapButton;