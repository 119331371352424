import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';

const LeadConfirmation = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          padding: '10px 32px',
          minWidth: 300,
          textAlign: 'center',
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <Header />
        <Typography sx={{ color:'#0073d9', fontSize: 18, fontWeight: 'regular', marginTop: '10px'}}>
          Thank you for joining our waitlist.
        </Typography>
        <Typography sx={{ color: 'text.secondary', fontSize: 15, fontWeight: 'regular', marginTop: '10px'}}>
          We are opening a location in your neighborhood soon, stay tuned.
        </Typography>
        <Box sx={{ marginTop: '20px'}}>
          <Button variant="outlined" color="primary" onClick={() => navigate('/')}>
            Go to Homepage
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LeadConfirmation;
